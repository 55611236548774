import React, { useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { FormElement, Req, SubmitButton } from "../blocks/forms";
import { Column, Grid } from "../blocks";
import ReCAPTCHA from "react-google-recaptcha";
import english from "../../../content/translations/en-CA/contact.json";
import PrivacyPolicy from "./PrivacyPolicy";

const NewContactUsForm = ({ returnURL }) => {
  const RecaptchaKey = "6LdRTiAdAAAAAJKnd7u1oETrHAP417MWwQZsBjkJ";
  const URL =
    "https://webto.salesforce.com/servlet/servlet.WebToLead?encoding=UTF-8";
  const {
    register,
    handleSubmit,
    formState,
    setError,
    reset,
    clearErrors,
  } = useForm({
    mode: "onChange",
  });
  const { errors } = formState;
  const [recaptchaToken, setRecaptchaToken] = useState(null);
  const captchaRef = useRef();
  const recaptcha = "contact-recaptcha";

  const isNotEmpty = value => {
    return value.trim() !== "" || null || undefined;
  };

  const submitForm = async data => {
    if (!recaptchaToken) {
      setError("contact-recaptcha", {
        type: "manual",
        message: "Captcha must be completed.",
      });
      return;
    }
    document.getElementById("demo-form").submit();

    reset();
    setRecaptchaToken(null);
  };

  const handleRecaptchaChange = token => {
    setRecaptchaToken(token);
    clearErrors();
  };

  const message = "00NC0000005VhZf";

  return (
    <form
      id="demo-form"
      action={URL}
      method="POST"
      onSubmit={handleSubmit(submitForm)}
      style={{ maxWidth: "1280px", margin: "20px auto", fontWeight: "700" }}
      noValidate
    >
      <fieldset style={{ border: "none" }}>
        <legend></legend>
        <input
          type="hidden"
          id="WebToLead_Source__c"
          name="WebToLead_Source__c"
          value=""
          className="text"
        />
        <input
          type="hidden"
          id="Contact_Us_Type__c"
          name="Contact_Us_Type__c"
          value=""
          className="text"
        />
        <input type="hidden" name="oid" value="00D80000000LKWA" />
        <input
          type="hidden"
          className="return_url"
          name="retURL"
          value={`${returnURL}?submitted`}
        />
        {/* <!-- Partner Lead Record --> */}
        <input
          type="hidden"
          id="recordType"
          name="recordType"
          value="012C0000000Btge"
        />
        <Grid columns={2} form>
          <Column>
            <FormElement>
              <label htmlFor="first_name">
                {english.contact_form.fields.firstName}
                <Req />
              </label>
              <input
                id="first_name"
                maxLength="40"
                name="first_name"
                placeholder="First name"
                type="text"
                {...register("first_name", {
                  required: english.contact_form.validation.fname,
                  pattern: {
                    value: /^[a-zA-Z\s\-']+$/,
                    message: "First name is not valid.",
                  },
                  validate: firstName =>
                    isNotEmpty(firstName) ||
                    english.contact_form.validation.fname,
                })}
              />
              <small>{errors.first_name?.message}</small>
            </FormElement>
          </Column>
          <Column>
            <FormElement>
              <label htmlFor="last_name">
                {english.contact_form.fields.lastName}
                <Req />
              </label>
              <input
                id="last_name"
                className="last_name"
                maxLength="80"
                name="last_name"
                placeholder="Last name"
                type="text"
                {...register("last_name", {
                  required: english.contact_form.validation.lname,
                  pattern: {
                    value: /^[a-zA-Z\s\-']+$/,
                    message: "Last name is not valid.",
                  },
                  validate: lastName =>
                    isNotEmpty(lastName) ||
                    english.contact_form.validation.lname,
                })}
              />
              <small>{errors.last_name?.message}</small>
            </FormElement>
          </Column>
        </Grid>
        <Grid columns={2} form>
          <Column>
            <FormElement>
              <label htmlFor={"email"}>
                {english.contact_form.fields.email}
                <Req />
              </label>
              <input
                id="email"
                className="email"
                maxLength="80"
                name="email"
                placeholder="Business Email"
                size="20"
                type="email"
                {...register("email", {
                  required: english.contact_form.validation.email_required,
                  pattern: {
                    value: /^([a-z.%+-]+)@([a-z-]+\.)+([a-z]{2,})$/,
                    message: english.contact_form.validation.email_invalid,
                  },
                  validate: email => {
                    let regex = /gmail|yahoo|hotmail|icloud/;
                    return (
                      !regex.test(email) ||
                      "Please enter your business email address. Personal email addresses such as Gmail, Hotmail and Yahoo are not accepted."
                    );
                  },
                })}
              />
              <small>{errors.email?.message}</small>
            </FormElement>
          </Column>
          <Column>
            <FormElement>
              <label htmlFor="company">
                {english.contact_form.fields.company}
                <Req />
              </label>
              <input
                id="company"
                className="company"
                maxLength="40"
                name="company"
                placeholder="Company"
                size="20"
                type="text"
                {...register("company", {
                  required: english.contact_form.validation.company,
                  pattern: {
                    value: /^[a-zA-Z0-9]+/,
                    message: "Company is not valid.",
                  },
                  validate: company =>
                    isNotEmpty(company) ||
                    english.contact_form.validation.company,
                })}
              />
              <small>{errors.company?.message}</small>
            </FormElement>
          </Column>
        </Grid>

        <FormElement single>
          <label htmlFor={"phone"}>{english.contact_form.fields.phone}</label>
          <input
            id="phone"
            className="phone"
            maxLength="40"
            name="phone"
            placeholder="Phone Number"
            size="20"
            type="tel"
            {...register("phone", {
              pattern: {
                value: /^(?=.*[0-9])[- +()0-9]+$/,
                message: english.contact_form.validation.phone,
              },
            })}
          />
          <small>{errors.phone?.message}</small>
        </FormElement>

        <FormElement single>
          <label htmlFor={"00NC0000005VhZf"}>
            {english.contact_form.fields.desc}
          </label>
          <textarea
            style={{ height: 150 }}
            id="00NC0000005VhZf"
            name="00NC0000005VhZf"
            placeholder="Tell us more about your loyalty program revenue goals."
            {...register("00NOG000001GeKH", {
              pattern: {
                value: /^[\w\s\d,.!?'"():;-]+/,
                message: "Format is not valid.",
              },
            })}
          ></textarea>
          <small>{errors[message]?.message}</small>
        </FormElement>

        <FormElement single>
          <ReCAPTCHA
            hl={"en"}
            id="contact-recaptcha"
            name="contact-recaptcha"
            sitekey={RecaptchaKey}
            size="normal"
            ref={captchaRef}
            onChange={handleRecaptchaChange}
          />
          <small>{errors[recaptcha]?.message}</small>
        </FormElement>

        <input
          id="middle_name"
          name="middle_name"
          type="hidden"
          tabIndex="-1"
          value="&nbsp;"
        />

        <FormElement single>
          <PrivacyPolicy
            policy={
              "By clicking “Submit”, you agree to send your email address to Points so they may contact you about the above request. Points.com Inc. agrees to use your info in accordance with their "
            }
            link={"https://www.points.com/legal/privacy/"}
            clickableText={"privacy policy."}
          ></PrivacyPolicy>
        </FormElement>

        <SubmitButton
          type={"submit"}
          name="btnSubmit"
          value={"Submit"}
          //   label={english.contact_form.fields.buttonLabel}
        />
      </fieldset>
    </form>
  );
};

export default NewContactUsForm;
